<template>
  <div class="user-role">
    <!-- <div style="padding: 12px 20px;">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search()">查询
      </el-button>
    </div> -->
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%;" row-key="rid" :data="dataList" border stripe>
      <el-table-column prop="roleName" label="角色名称" :width="$system.calWidth(dataList, 'roleName', '角色名称')"
                       header-align="left" align="left"/>
      <el-table-column prop="roleDesc" label="角色描述"
                       :width="$system.calWidth(dataList, 'roleDesc', '角色描述', undefined, 360)" header-align="left"
                       align="left"/>
      <el-table-column fixed="right" label="操作" min-width="74px" header-align="left" align="left">
        <template slot-scope="scope">
          <el-checkbox class="zwx-checkbox" v-model="scope.row.ifAuth"
                       @click.native.prevent="$system.msgbox(scope.row, '提示', scope.row.ifAuth ? '确定要取消授权吗？' : '确定要授权吗？', '确定', '取消', userRoleDistribution)">
            授权
          </el-checkbox>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'NavigationRoleIndex',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rid: this.$route.params.rid,
      menuTitle: this.$route.params.menuTitle,
      dataList: [],
    }
  },
  created() {
    this.$emit('breadcrumb', this.menuTitle, false)
  },
  mounted() {
    this.search()
  },
  computed: {},
  methods: {
    search() {
      this.$emit('loading', true)
      let data = {
        rid: this.rid,
      }
      this.$system.get(this.api + '/systematic/getNavigationRoleList-1', data, true, true, this.searchSuccess, this.error)
    },
    searchSuccess(data) {
      if (data.type === '00') {
        this.dataList = data.roleList
      } else if (data.type === '99') {
        this.$router.push({name: 'ErrorPage', params: {mess: data.mess}})
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    userRoleDistribution(row) {
      this.$emit('loading', true)
      let data = {
        mainId: this.rid,
        roleCode: row.roleCode,
        ifCheck: !row.ifAuth,
      }
      this.$system.postJson(this.api + '/systematic/navigationRoleDistribution-1', data, true, true, this.userRoleDistributionSuccess, this.error)
    },
    userRoleDistributionSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.search()
      } else if (data.type === '99') {
        this.$router.push({name: 'ErrorPage', params: {mess: data.mess}})
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="less" scoped></style>

<style lang="less"></style>
