var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-role" },
    [
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid",
            data: _vm.dataList,
            border: "",
            stripe: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "roleName",
              label: "角色名称",
              width: _vm.$system.calWidth(_vm.dataList, "roleName", "角色名称"),
              "header-align": "left",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roleDesc",
              label: "角色描述",
              width: _vm.$system.calWidth(
                _vm.dataList,
                "roleDesc",
                "角色描述",
                undefined,
                360
              ),
              "header-align": "left",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": "74px",
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "zwx-checkbox",
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$system.msgbox(
                              scope.row,
                              "提示",
                              scope.row.ifAuth
                                ? "确定要取消授权吗？"
                                : "确定要授权吗？",
                              "确定",
                              "取消",
                              _vm.userRoleDistribution
                            )
                          }
                        },
                        model: {
                          value: scope.row.ifAuth,
                          callback: function($$v) {
                            _vm.$set(scope.row, "ifAuth", $$v)
                          },
                          expression: "scope.row.ifAuth"
                        }
                      },
                      [_vm._v(" 授权 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }